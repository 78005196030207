<template>
  <fd-form
    class="property-change-status card p-4"
    @submit="$emit('update', { id: property.id, status: status })"
  >
    <div class="ref-code">{{ property.referenceCode }}</div>
    <fd-form-section title="Change Status">
      <fd-select
        v-model="status"
        class="col-12"
        label="Select New Status"
        :options="options"
        :disabled="!canChangeStatus"
        :validators="[validators.required]"
      >
      </fd-select>
      <p
        v-if="!canChangeStatus"
        class="bg-grey-200 fg-grey-800 px-3 py-1 mt-2"
        :style="{ borderRadius: '4px' }"
      >
        Cannot change status while property partnership is active.
      </p>
    </fd-form-section>
    <div class="text-right">
      <button type="button" class="btn mr-1" @click="$emit('close')">
        Cancel
      </button>
      <button class="btn main">Update</button>
    </div>
  </fd-form>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import propertyStatusEnum from "@/enums/propertyStatus";

export default {
  components: {},
  mixins: [],
  props: {
    property: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      status: "",
      propertyStatusEnum,
      options: [],

      validators: {
        required: required
      }
    };
  },
  computed: {
    canChangeStatus() {
	return true;
     // return this.property.allwPartnership;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initOptions();
    this.initStatus();
  },
  methods: {
    initOptions() {
      for (let status in propertyStatusEnum) {
        this.options.push({
          id: propertyStatusEnum[status],
          name: propertyStatusEnum[status]
        });
      }
    },
    initStatus() {
      this.status = this.property.status;
    }
  }
};
</script>

<style lang="scss">
.ref-code {
  margin-bottom: 12px;
  border: solid 1px $color-secondary;
  width: fit-content;
  border-radius: 5px;
  padding: 6px 15px;
  color: $color-secondary;
}
</style>
